* { box-sizing: border-box; }

.App {
  text-align: justify;
  display: flex;
  flex-direction: column; /* Ensure the header stays at the top */
  min-height: 100vh;
  background: white;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.App-header {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(221, 112, 112, 0) 100%); /* Gradient from slightly transparent black to fully transparent */
  width: 100%; /* Full width */
  height: 150px;
  display: flex;
  align-items: center;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure it stays above other content */
}

.App-container {
  max-width: 1200px; /* Set your desired max-width */
  width: 100%;
  margin: 0 auto; /* Center the container */
  padding: 0 20px; /* Add padding to the sides */
  display: flex;
  justify-content: center; /* Space between logo and nav */
  align-items: center;
}

.App-logo {
  width: 155px;
  height: auto;
}

.App-nav {
  display: none;
  gap: 20px; /* Space between nav items */
}

.App-nav a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.App-hero {
  width: 100%; /* Full width */
  height: 100vh; /* Fill remaining viewport height */
  background-color: black;
  background-image: url('./neon.webp'); /* Path to your background image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: space-between; /* Space between nav and hero text */
}

.hero-text {
  color: white;
  font-size: 36px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 8%;
  padding: 0 20px;
}

.who-we-are {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  line-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.4em 20px;
}

.who-we-are h1 { color: #333; }
.who-we-are p { color: #444;}

.shopify,
.marketing ,
.ai,
.contact { 
  position: relative;
  width: 100%; 
  line-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.4em 20px;
}

.contact { 
  background-color: #fefefe;
  color: #2596be;
  text-align: center;
}

.ai::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)); /* Dark radial gradient overlay */
  z-index: 1; /* Ensure the overlay is on top */
  pointer-events: none; /* Allow clicks to pass through the overlay */
}

.ai > * {
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
}

.ai {
  background-image: url('./ai.webp'); /* Path to your background image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: flex; /* Use flexbox */
}

.shopify {  background-color: #efefef; }
.marketing { background-color: #e0e0e0; }
.ai { 
  background-color: #0f0f0f;
  color: white; 
}

.shopify-container,
.marketing-container,
.ai-container,
.contact-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.ai-container { 
  background-color: #161616;
  padding: 1em 1.5em;
  border-radius: 40px;
}

.shopify ul,
.marketing ul { color: #444;}

.shopify ul,
.marketing ul,
.ai ul { 
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.shopify ul strong,
.marketing ul strong,
.ai ul strong {
  font-size: 17px;
  font-weight: 400;
}

.shopify ul span,
.marketing ul span,
.ai ul span {
  display: none;
}


.shopify-image,
.marketing-image,
.ai-image { 
  width: 100%; 
  margin-top: 20px; 
  display: none;
  justify-content: center;
  align-items: center;
}

.shopify-image img,
.marketing-image img,
.ai img { 
  width: 100%;
  max-width: 500px; 
  border-radius: 50%;
}

.shopify h1,
.shopify ul strong { color: #EA61B6; }
.marketing h1,
.marketing ul strong { color: #2596be; }
.ai h1,
.ai ul strong { color: #25be7e; }

footer { 
  text-align: center;
  color: #444;
  font-size: 14px;
}

nav a { color: white }
nav a:hover { color: #2596be; }

h1, h2, h3, h4 { text-align: center;}

/* Mobile-first adjustments */
@media (max-width: 600px) {
  .App-header {
    height: 100px; /* Adjust header height for mobile */
  }

  .App-logo {
    width: 150px; /* Adjust logo size for mobile */
  }

  .App-nav {
    font-size: 16px; /* Adjust nav font size for mobile */
  }

  .App-hero {
    background-size: cover; /* Ensure the background covers the screen */
    background-position: center; /* Center the image */
  }

  .hero-text {
    font-size: 24px; /* Adjust hero text size for mobile */
    margin-bottom: 10%; /* Adjust margin for mobile */
  }
}

@media (min-width: 800px) {
  .App-nav {
    display: flex;
  }

  .App-container {
    justify-content: space-between; /* Center the logo horizontally */
  }

  .App-logo {
    width: 170px;
  }

  .shopify p,
  .marketing p,
  .ai p { max-width: 900px; }

  .shopify ul,
  .marketing ul,
  .ai ul {
    display: flex;
    align-items: first baseline;
    text-align: left;
  }

  .shopify ul strong,
  .marketing ul strong,
  .ai ul strong {
    padding-right: 10px;
    font-weight: 500;
    display: inline;
  }
  
  .shopify ul span,
  .marketing ul span {
    display: inline;
    color: #444;
  }

  .ai ul span { display: inline; }

  .shopify-image,
  .marketing-image,
  .ai-image {
    display: flex; 
    width: 40%;
  }

  .shopify-image img,
  .marketing-image img,
  .ai-image img { 
    width: 350px;
    max-width: 90%;
  }

  .shopify-container,
  .marketing-container,
  .ai-container { 
    flex-direction: row;
    align-items: center;
  }

  .ai-container {
    padding: 2em 2.5em;
  }
}